import React from 'react';
import styled from 'styled-components';

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper className='animate pointer radius8' href={action} border={border}>
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.a`
  border: 1px solid ${(props) => (props.border ? '#707070' : '#7620ff')};
  background-color: ${(props) => (props.border ? 'transparent' : '#7620ff')};
  width: 100%;
  padding: 15px 25px;
  font-size: 0.85rem;
  outline: none;
  color: ${(props) => (props.border ? '#707070' : '#fff')};
  :hover {
    background-color: ${(props) => (props.border ? 'transparent' : '#580cd2')};
    border: 1px solid #7620ff;
    color: ${(props) => (props.border ? '#7620ff' : '#fff')};
  }
`;
