import * as React from 'react';

function SvgComponent({ black }) {
  return (
    <svg
      viewBox='0 0 311.854 124'
      fill={black ? '#000' : '#fff'}
      width='100%'
      height='45px'
    >
      <path d='M77.46 61.98L64.92 77.91l33.62 43.05h24.92l-46-58.98zM98.54 3L64.92 46.06l12.54 15.92 46-58.98H98.54zm-46 58.98l-46.3 58.98h25.21l33.47-43.05-12.38-15.93zM31.45 3H6.24l46.3 58.98 12.38-15.92L31.45 3z'></path>
      <path
        d='M77.46 61.98L64.92 77.91l33.62 43.05h24.92l-46-58.98zM98.54 3L64.92 46.06l12.54 15.92 46-58.98H98.54zm-46 58.98l-46.3 58.98h25.21l33.47-43.05-12.38-15.93zM31.45 3H6.24l46.3 58.98 12.38-15.92L31.45 3z'
        stroke-miterlimit='10'
      ></path>
      <g>
        <path d='M116.32 74.62c-1.68-1.752-3.03-3.734-4-6.044-.973-2.312-1.474-4.713-1.456-7.252A18.435 18.435 0 01112.42 54a19.184 19.184 0 014.083-5.896c1.752-1.728 3.735-3.03 6.045-4.004a18.104 18.104 0 017.298-1.453c2.586.017 5.027.504 7.277 1.507a19.53 19.53 0 015.992 4.133l-4.499 4.341c-2.471-2.462-5.427-3.657-8.813-3.681a12.908 12.908 0 00-4.895.907c-1.558.648-2.88 1.532-4.016 2.7a12.763 12.763 0 00-2.707 3.93 12.255 12.255 0 00-1.02 4.883c-.012 1.74.304 3.34.954 4.897.648 1.51 1.53 2.832 2.7 3.968 1.12 1.184 2.43 2.085 3.93 2.754 1.548.621 3.143.96 4.883.974l7.254 4.891c-2.263.973-4.713 1.427-7.297 1.408-2.586-.017-5.028-.55-7.278-1.554-2.296-1.002-4.26-2.378-5.99-4.085zM176.868 48.517c3.69 3.692 5.54 8.125 5.504 13.297-.036 5.22-1.945 9.625-5.685 13.22-3.695 3.643-8.077 5.493-13.298 5.457-5.219-.036-9.577-1.945-13.22-5.638-3.641-3.694-5.492-8.078-5.456-13.297.035-5.172 1.947-9.579 5.638-13.22 3.646-3.643 8.078-5.494 13.296-5.459 5.22.037 9.578 1.948 13.22 5.64zm-22.173 4.268c-2.463 2.428-3.66 5.38-3.683 8.814-.024 3.431 1.13 6.448 3.561 8.91 2.426 2.462 5.427 3.66 8.86 3.682 3.433.025 6.402-1.131 8.864-3.56 2.509-2.474 3.705-5.43 3.729-8.86.022-3.434-1.18-6.404-3.608-8.865-2.429-2.461-5.38-3.703-8.814-3.727-3.432-.023-6.4 1.131-8.91 3.606z'></path>
        <path d='M206.299 61.978c-.018 2.586-.505 4.98-1.509 7.23-1.05 2.296-2.38 4.262-4.082 5.992-1.705 1.68-3.738 3.029-6.047 4a18.066 18.066 0 01-7.298 1.455l-12.507-.086 6.298-6.257 6.253.043a12.957 12.957 0 004.895-.905c1.508-.65 2.88-1.532 4.015-2.701a13.498 13.498 0 002.707-3.979 12.23 12.23 0 001.021-4.835 12.233 12.233 0 00-.955-4.851 12.847 12.847 0 00-2.698-4.014 13.114 13.114 0 00-3.979-2.754c-1.5-.623-3.142-.96-4.835-.973l-6.253-.043-6.212-6.343 12.508.084c2.584.02 4.979.552 7.277 1.555 2.25 1.003 4.262 2.38 5.944 4.085 1.726 1.75 3.03 3.733 4.048 6.047.972 2.262 1.426 4.664 1.409 7.25zM220.172 74.58l-6.298 6.257.258-37.614 6.212 6.25-.172 25.107zM263.732 43.562l-.256 37.52-.001.047-.001.047-31.073-28.33.065-9.497 24.86 22.692.154-22.521 6.252.042zM298.992 70.886l6.24 2.112a17.66 17.66 0 01-3.032 3.46 19.349 19.349 0 01-3.733 2.655 16.283 16.283 0 01-4.29 1.663c-1.509.412-3.06.588-4.707.577-5.22-.035-9.578-1.946-13.22-5.637-3.643-3.694-5.494-8.079-5.458-13.298.035-5.172 1.898-9.578 5.64-13.22 3.645-3.69 8.075-5.494 13.296-5.457 2.585.017 5.027.505 7.323 1.508a19.504 19.504 0 015.991 4.131l-4.543 4.39c-2.43-2.463-5.334-3.704-8.814-3.728-3.433-.025-6.401 1.13-8.91 3.605-2.462 2.429-3.66 5.383-3.683 8.816-.024 3.431 1.131 6.448 3.56 8.91 2.428 2.462 5.428 3.656 8.862 3.682 1.879.013 3.62-.353 5.226-1.046a13.09 13.09 0 004.252-3.123zm-9.371-11.441l15.703.107-6.296 6.21-9.45-.064.043-6.253z'></path>
      </g>
    </svg>
  );
}

export default SvgComponent;
