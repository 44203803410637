import * as React from 'react';

const SvgComponent = (props) => (
  <svg viewBox='-25 0 200 200' xmlSpace='preserve' {...props}>
    <path
      style={{
        fill: '#bcbeff',
      }}
      d='M74.833 200 .785 157.248l74.045-42.75 74.048 42.752z'
    />
    <path
      style={{
        fill: '#bcbeff',
      }}
      d='M74.048 168.272 0 125.52l74.046-42.75 74.048 42.752z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='m74.048 156.599-53.83-31.079 53.828-31.077 53.829 31.078z'
    />
    <path
      style={{
        fill: '#fff',
      }}
      d='m74.046 96.548 52.006 30.026 1.823-1.053-53.829-31.078L20.22 125.52l1.823 1.053z'
    />
    <linearGradient
      id='a'
      gradientUnits='userSpaceOnUse'
      x1={111.071}
      y1={177.543}
      x2={111.071}
      y2={126.942}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#c0bff6',
        }}
      />
      <stop
        offset={0.294}
        style={{
          stopColor: '#c5c4f7',
        }}
      />
      <stop
        offset={0.64}
        style={{
          stopColor: '#d3d2f9',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#e9e9fd',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#a)',
      }}
      d='M148.094 125.522v24.232l-74.046 42.75v-24.232z'
    />
    <path
      style={{
        fill: '#24126a',
      }}
      d='M0 125.52v24.233l74.048 42.752v-24.233z'
    />
    <g
      style={{
        opacity: 0.51,
      }}
    >
      <path
        style={{
          fill: '#453c9e',
        }}
        d='M5.016 136.408v7.631L69.032 181v-7.631z'
      />
    </g>
    <path
      style={{
        fill: '#fff',
      }}
      d='M74.048 166.883 1.203 124.826 0 125.52l74.048 42.752 74.046-42.75-1.203-.695z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='M136.987 142.503v4.337l-51.832 29.925v-4.337z'
    />
    <path
      style={{
        opacity: 0.51,
        fill: '#453c9e',
      }}
      d='M0 125.52v24.233l74.048 42.752v-24.233z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m52.432 166.985-13.409-7.743-.002 1.6 13.41 7.742zM37.09 158.143l-3.633-2.098-.002 1.599 3.633 2.098zM20.07 148.319l-7.433-4.292-.001 1.599 7.433 4.292zM26.854 152.235l-5.376-3.103-.001 1.599 5.375 3.104zM31.894 155.146l-3.633-2.098-.001 1.6 3.633 2.097zM74.123 105.114l-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.826 107.83-2.352-1.358 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m83.528 110.544-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m88.23 113.26-2.35-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m92.933 115.974-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m97.636 118.69-2.351-1.358 2.35-1.358 2.352 1.358zM102.339 121.404l-2.352-1.357 2.352-1.358 2.35 1.358zM107.041 124.12l-2.351-1.358 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m111.744 126.835-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.401 107.84-2.35-1.357 2.35-1.358 2.352 1.358zM74.104 110.555l-2.351-1.357 2.35-1.358 2.352 1.358zM78.807 113.27l-2.352-1.357 2.352-1.358 2.35 1.358zM83.51 115.985l-2.352-1.357 2.351-1.358 2.351 1.358zM88.212 118.7l-2.352-1.357 2.352-1.358 2.351 1.358zM92.914 121.415l-2.35-1.357 2.35-1.358 2.352 1.358zM97.617 124.13l-2.351-1.357 2.351-1.358 2.351 1.358zM102.32 126.846l-2.352-1.358 2.352-1.358 2.351 1.358zM107.022 129.56l-2.351-1.357 2.351-1.357 2.351 1.357zM64.68 110.566l-2.352-1.357 2.352-1.358 2.351 1.358zM69.382 113.281l-2.351-1.357 2.351-1.358 2.352 1.358zM74.085 115.996l-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.788 118.711-2.352-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m83.49 121.426-2.351-1.357 2.351-1.358 2.351 1.358zM88.193 124.141l-2.351-1.357 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.895 126.856-2.35-1.357 2.35-1.358 2.352 1.358zM97.598 129.572l-2.351-1.358 2.351-1.357 2.351 1.357zM102.3 132.287l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.958 113.292-2.351-1.357 2.351-1.358 2.351 1.358zM64.66 116.007l-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.363 118.722-2.351-1.357 2.351-1.358 2.351 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.066 121.437-2.351-1.357 2.35-1.358 2.352 1.358zM78.769 124.153l-2.352-1.358 2.351-1.358 2.352 1.358zM83.471 126.868l-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m88.174 129.583-2.352-1.358 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.876 132.298-2.351-1.358 2.351-1.357 2.352 1.357zM97.579 135.013l-2.351-1.358 2.35-1.357 2.352 1.357zM55.236 116.018l-2.35-1.357 2.35-1.358 2.352 1.358zM59.939 118.733l-2.351-1.357 2.35-1.358 2.352 1.358zM64.642 121.448l-2.352-1.357 2.352-1.358 2.35 1.358zM69.344 124.163l-2.351-1.357 2.351-1.358 2.352 1.358zM74.047 126.879l-2.351-1.358 2.35-1.357 2.352 1.357zM78.75 129.594l-2.352-1.358 2.351-1.357 2.352 1.357zM83.452 132.309l-2.351-1.358 2.351-1.357 2.351 1.357zM88.155 135.024l-2.352-1.358 2.352-1.357 2.35 1.357zM92.857 137.739l-2.35-1.358 2.35-1.357 2.352 1.357zM50.515 118.744l-2.352-1.357 2.352-1.358 2.35 1.358zM55.217 121.46l-2.35-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.92 124.174-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m64.623 126.89-2.352-1.358 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.325 129.605-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.028 132.32-2.352-1.358 2.352-1.357 2.351 1.357zM78.73 135.035l-2.35-1.358 2.35-1.357 2.352 1.357zM83.433 137.75l-2.351-1.358 2.35-1.357 2.352 1.357zM88.136 140.465l-2.352-1.358 2.352-1.357 2.35 1.357zM45.793 121.47l-2.351-1.357 2.35-1.358 2.352 1.358zM50.496 124.185l-2.352-1.357 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m55.198 126.9-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m59.901 129.616-2.351-1.358 2.35-1.358 2.352 1.358zM64.603 132.33l-2.35-1.357 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.306 135.046-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m74.009 137.76-2.352-1.357 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.711 140.476-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m83.414 143.19-2.351-1.357 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m41.071 124.197-2.35-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m45.774 126.911-2.351-1.357 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m50.477 129.626-2.352-1.357 2.352-1.358 2.351 1.358zM55.18 132.342l-2.352-1.358 2.351-1.357 2.351 1.357zM59.882 135.057l-2.351-1.358 2.35-1.357 2.352 1.357zM64.585 137.772l-2.352-1.358 2.351-1.357 2.352 1.357zM69.287 140.487l-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m73.99 143.202-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.692 145.917-2.351-1.358 2.351-1.357 2.352 1.357zM36.35 126.923l-2.351-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m41.052 129.637-2.35-1.357 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m45.755 132.353-2.351-1.358 2.351-1.358 2.351 1.358zM50.458 135.068l-2.352-1.358 2.351-1.357 2.352 1.357zM55.16 137.783l-2.351-1.358 2.351-1.357 2.351 1.357zM59.863 140.498l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m64.566 143.213-2.352-1.358 2.351-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.268 145.928-2.351-1.358 2.351-1.357 2.351 1.357zM73.97 148.643l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#bcbeff',
      }}
      d='M74.048 127.414 0 84.662l74.046-42.75 74.048 42.752z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='m74.048 115.74-53.83-31.077 53.828-31.078 53.829 31.078z'
    />
    <path
      style={{
        fill: '#fff',
      }}
      d='m74.046 55.69 52.006 30.026 1.823-1.053-53.829-31.078L20.22 84.662l1.823 1.053z'
    />
    <linearGradient
      id='b'
      gradientUnits='userSpaceOnUse'
      x1={111.071}
      y1={136.685}
      x2={111.071}
      y2={86.084}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#c0bff6',
        }}
      />
      <stop
        offset={0.294}
        style={{
          stopColor: '#c5c4f7',
        }}
      />
      <stop
        offset={0.64}
        style={{
          stopColor: '#d3d2f9',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#e9e9fd',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#b)',
      }}
      d='M148.094 84.664v24.232l-74.046 42.75v-24.232z'
    />
    <path
      style={{
        fill: '#24126a',
      }}
      d='M0 84.662v24.233l74.048 42.752v-24.233z'
    />
    <g
      style={{
        opacity: 0.51,
      }}
    >
      <path
        style={{
          fill: '#453c9e',
        }}
        d='M5.016 95.55v7.631l64.016 36.96v-7.631z'
      />
    </g>
    <path
      style={{
        fill: '#fff',
      }}
      d='M74.048 126.025 1.203 83.968 0 84.662l74.048 42.752 74.046-42.75-1.203-.695z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='M136.987 101.645v4.337l-51.832 29.925v-4.337z'
    />
    <path
      style={{
        opacity: 0.51,
        fill: '#453c9e',
      }}
      d='M0 84.662v24.233l74.048 42.752v-24.233z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m52.432 126.127-13.409-7.743-.002 1.6 13.41 7.742zM37.09 117.285l-3.633-2.098-.002 1.599 3.633 2.098zM20.07 107.46l-7.433-4.291-.001 1.599 7.433 4.292zM26.854 111.377l-5.376-3.103-.001 1.599 5.375 3.104zM31.894 114.288l-3.633-2.098-.001 1.6 3.633 2.097zM74.123 64.256 71.772 62.9l2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.826 66.971-2.352-1.357 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m83.528 69.686-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m88.23 72.401-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m92.933 75.116-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m97.636 77.831-2.351-1.357 2.35-1.358 2.352 1.358zM102.339 80.546l-2.352-1.357 2.352-1.358 2.35 1.358zM107.041 83.262l-2.351-1.358 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m111.744 85.977-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.401 66.982-2.35-1.357 2.35-1.358 2.352 1.358zM74.104 69.697l-2.351-1.357 2.35-1.358 2.352 1.358zM78.807 72.412l-2.352-1.357 2.352-1.358 2.35 1.358zM83.51 75.127l-2.352-1.357 2.351-1.358 2.351 1.358zM88.212 77.842l-2.352-1.357 2.352-1.358 2.351 1.358zM92.914 80.557l-2.35-1.357 2.35-1.358 2.352 1.358zM97.617 83.272l-2.351-1.357 2.351-1.358 2.351 1.358zM102.32 85.988l-2.352-1.358 2.352-1.357 2.351 1.357zM107.022 88.703l-2.351-1.358 2.351-1.357 2.351 1.357zM64.68 69.708l-2.352-1.357 2.352-1.358 2.351 1.358zM69.382 72.423l-2.351-1.357 2.351-1.358 2.352 1.358zM74.085 75.138l-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.788 77.853-2.352-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='M83.49 80.569 81.14 79.21l2.351-1.358 2.351 1.358zM88.193 83.284l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.895 85.998-2.35-1.357 2.35-1.357 2.352 1.357zM97.598 88.714l-2.351-1.358L97.598 86l2.351 1.357zM102.3 91.429l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.958 72.434-2.351-1.357 2.351-1.358 2.351 1.358zM64.66 75.15l-2.35-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.363 77.864-2.351-1.357 2.351-1.358 2.351 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.066 80.58-2.351-1.358 2.35-1.358 2.352 1.358zM78.769 83.295l-2.352-1.358 2.351-1.357 2.352 1.357zM83.471 86.01l-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m88.174 88.725-2.352-1.358 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.876 91.44-2.351-1.358 2.351-1.357 2.352 1.357zM97.579 94.155l-2.351-1.358 2.35-1.357 2.352 1.357zM55.236 75.16l-2.35-1.357 2.35-1.358 2.352 1.358zM59.939 77.875l-2.351-1.357 2.35-1.358 2.352 1.358zM64.642 80.59l-2.352-1.357 2.352-1.358 2.35 1.358zM69.344 83.305l-2.351-1.357 2.351-1.358 2.352 1.358zM74.047 86.02l-2.351-1.357 2.35-1.357 2.352 1.357zM78.75 88.736l-2.352-1.358 2.351-1.357 2.352 1.357zM83.452 91.45l-2.351-1.357 2.351-1.357 2.351 1.357zM88.155 94.166l-2.352-1.358 2.352-1.357 2.35 1.357zM92.857 96.88l-2.35-1.357 2.35-1.357 2.352 1.357zM50.515 77.886l-2.352-1.357 2.352-1.358 2.35 1.358zM55.217 80.601l-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.92 83.316-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m64.623 86.032-2.352-1.358 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.325 88.747-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.028 91.462-2.352-1.358 2.352-1.357 2.351 1.357zM78.73 94.177l-2.35-1.358 2.35-1.357 2.352 1.357zM83.433 96.892l-2.351-1.358 2.35-1.357 2.352 1.357zM88.136 99.607l-2.352-1.358 2.352-1.357 2.35 1.357zM45.793 80.612l-2.351-1.357 2.35-1.358 2.352 1.358zM50.496 83.327l-2.352-1.357 2.352-1.357 2.35 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m55.198 86.042-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='M59.901 88.758 57.55 87.4l2.35-1.357 2.352 1.357zM64.603 91.473l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.306 94.188-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m74.009 96.903-2.352-1.358 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.711 99.618-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m83.414 102.333-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m41.071 83.339-2.35-1.358 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m45.774 86.053-2.351-1.357 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m50.477 88.769-2.352-1.358 2.352-1.358 2.351 1.358zM55.18 91.484l-2.352-1.358 2.351-1.357 2.351 1.357zM59.882 94.199 57.53 92.84l2.35-1.357 2.352 1.357zM64.585 96.914l-2.352-1.358 2.351-1.357 2.352 1.357zM69.287 99.629l-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m73.99 102.344-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.692 105.059-2.351-1.358 2.351-1.357 2.352 1.357zM36.35 86.065l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m41.052 88.78-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m45.755 91.495-2.351-1.358 2.351-1.357 2.351 1.357zM50.458 94.21l-2.352-1.358 2.351-1.357 2.352 1.357zM55.16 96.925l-2.351-1.358 2.351-1.357 2.351 1.357zM59.863 99.64l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m64.566 102.355-2.352-1.358 2.351-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.268 105.07-2.351-1.358 2.351-1.357 2.351 1.357zM73.97 107.785l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#bcbeff',
      }}
      d='M74.048 86.556 0 43.804l74.046-42.75 74.048 42.752z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='m74.048 74.883-53.83-31.078 53.828-31.078 53.829 31.078z'
    />
    <path
      style={{
        fill: '#fff',
      }}
      d='m74.046 14.832 52.006 30.026 1.823-1.053-53.829-31.078L20.22 43.804l1.823 1.053z'
    />
    <linearGradient
      id='c'
      gradientUnits='userSpaceOnUse'
      x1={111.071}
      y1={95.827}
      x2={111.071}
      y2={45.226}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#c0bff6',
        }}
      />
      <stop
        offset={0.294}
        style={{
          stopColor: '#c5c4f7',
        }}
      />
      <stop
        offset={0.64}
        style={{
          stopColor: '#d3d2f9',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#e9e9fd',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#c)',
      }}
      d='M148.094 43.806v24.232l-74.046 42.75V86.557z'
    />
    <path
      style={{
        fill: '#24126a',
      }}
      d='M0 43.804v24.233l74.048 42.752V86.556z'
    />
    <g
      style={{
        opacity: 0.51,
      }}
    >
      <path
        style={{
          fill: '#453c9e',
        }}
        d='M5.016 54.692v7.631l64.016 36.96v-7.631z'
      />
    </g>
    <path
      style={{
        fill: '#fff',
      }}
      d='M74.048 85.167 1.203 43.11 0 43.804l74.048 42.752 74.046-42.75-1.203-.695z'
    />
    <path
      style={{
        fill: '#453c9e',
      }}
      d='M136.987 60.787v4.337L85.155 95.05v-4.338z'
    />
    <path
      style={{
        opacity: 0.51,
        fill: '#453c9e',
      }}
      d='M0 43.804v24.233l74.048 42.752V86.556z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m52.432 85.269-13.409-7.743-.002 1.6 13.41 7.742zM37.09 76.427l-3.633-2.098-.002 1.599 3.633 2.098zM20.07 66.603l-7.433-4.292-.001 1.599 7.433 4.292zM26.854 70.52l-5.376-3.104-.001 1.599 5.375 3.104zM31.894 73.43l-3.633-2.098-.001 1.6 3.633 2.097zM74.123 23.398l-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.826 26.113-2.352-1.357 2.352-1.358 2.35 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m83.528 28.828-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m88.23 31.543-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m92.933 34.258-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m97.636 36.973-2.351-1.357 2.35-1.358 2.352 1.358zM102.339 39.688l-2.352-1.357 2.352-1.358 2.35 1.358zM107.041 42.404l-2.351-1.358 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m111.744 45.119-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.401 26.124-2.35-1.357 2.35-1.358 2.352 1.358zM74.104 28.84l-2.351-1.358 2.35-1.358 2.352 1.358zM78.807 31.554l-2.352-1.357 2.352-1.358 2.35 1.358zM83.51 34.27l-2.352-1.358 2.351-1.358 2.351 1.358zM88.212 36.985l-2.352-1.358 2.352-1.358 2.351 1.358zM92.914 39.7l-2.35-1.358 2.35-1.357 2.352 1.357zM97.617 42.414l-2.351-1.357 2.351-1.358 2.351 1.358zM102.32 45.13l-2.352-1.358 2.352-1.357 2.351 1.357zM107.022 47.845l-2.351-1.358 2.351-1.357 2.351 1.357zM64.68 28.85l-2.352-1.357 2.352-1.358 2.351 1.358zM69.382 31.565l-2.351-1.357 2.351-1.358 2.352 1.358zM74.085 34.28l-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m78.788 36.995-2.352-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m83.49 39.71-2.351-1.357 2.351-1.358 2.351 1.358zM88.193 42.426l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.895 45.14-2.35-1.357 2.35-1.357 2.352 1.357zM97.598 47.856l-2.351-1.358 2.351-1.357 2.351 1.357zM102.3 50.57l-2.35-1.357 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.958 31.576-2.351-1.357 2.351-1.358 2.351 1.358zM64.66 34.291l-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.363 37.006-2.351-1.357 2.351-1.358 2.351 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.066 39.721-2.351-1.357 2.35-1.358 2.352 1.358zM78.769 42.437l-2.352-1.358 2.351-1.357 2.352 1.357zM83.471 45.152l-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m88.174 47.867-2.352-1.358 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m92.876 50.582-2.351-1.358 2.351-1.357 2.352 1.357zM97.579 53.297l-2.351-1.358 2.35-1.357 2.352 1.357zM55.236 34.302l-2.35-1.357 2.35-1.358 2.352 1.358zM59.939 37.017l-2.351-1.357 2.35-1.358 2.352 1.358zM64.642 39.732l-2.352-1.357 2.352-1.358 2.35 1.358zM69.344 42.448l-2.351-1.358 2.351-1.358 2.352 1.358zM74.047 45.163l-2.351-1.358 2.35-1.357 2.352 1.357zM78.75 47.878l-2.352-1.358 2.351-1.357 2.352 1.357zM83.452 50.593l-2.351-1.358 2.351-1.357 2.351 1.357zM88.155 53.308l-2.352-1.358 2.352-1.357 2.35 1.357zM92.857 56.023l-2.35-1.358 2.35-1.357 2.352 1.357zM50.515 37.028l-2.352-1.357 2.352-1.358 2.35 1.358zM55.217 39.743l-2.35-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m59.92 42.458-2.351-1.357 2.35-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m64.623 45.174-2.352-1.358 2.352-1.357 2.35 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.325 47.889-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m74.028 50.604-2.352-1.358 2.352-1.357 2.351 1.357zM78.73 53.319l-2.35-1.358 2.35-1.357 2.352 1.357zM83.433 56.034l-2.351-1.358 2.35-1.357 2.352 1.357zM88.136 58.749l-2.352-1.358 2.352-1.357 2.35 1.357zM45.793 39.755l-2.351-1.358 2.35-1.358 2.352 1.358zM50.496 42.47l-2.352-1.358 2.352-1.357 2.35 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m55.198 45.184-2.351-1.357 2.351-1.358 2.352 1.358z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m59.901 47.9-2.351-1.358 2.35-1.357 2.352 1.357zM64.603 50.615l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m69.306 53.33-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m74.009 56.045-2.352-1.358 2.352-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.711 58.76-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m83.414 61.475-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m41.071 42.48-2.35-1.357 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m45.774 45.196-2.351-1.358 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m50.477 47.91-2.352-1.357 2.352-1.357 2.351 1.357zM55.18 50.626l-2.352-1.358 2.351-1.357 2.351 1.357zM59.882 53.34l-2.351-1.357 2.35-1.357 2.352 1.357zM64.585 56.056l-2.352-1.358 2.351-1.357 2.352 1.357zM69.287 58.77l-2.351-1.357 2.351-1.357 2.351 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m73.99 61.486-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m78.692 64.2-2.351-1.357 2.351-1.357 2.352 1.357zM36.35 45.207l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.1,
        fill: '#42e8e0',
      }}
      d='m41.052 47.922-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m45.755 50.637-2.351-1.358 2.351-1.357 2.351 1.357zM50.458 53.352l-2.352-1.358 2.351-1.357 2.352 1.357zM55.16 56.067l-2.351-1.358 2.351-1.357 2.351 1.357zM59.863 58.782l-2.351-1.358 2.35-1.357 2.352 1.357z'
    />
    <path
      style={{
        opacity: 0.4,
        fill: '#42e8e0',
      }}
      d='m64.566 61.497-2.352-1.358 2.351-1.357 2.352 1.357z'
    />
    <path
      style={{
        fill: '#42e8e0',
      }}
      d='m69.268 64.212-2.351-1.358 2.351-1.357 2.351 1.358zM73.97 66.927l-2.35-1.358 2.35-1.357 2.352 1.357z'
    />
  </svg>
);

export default SvgComponent;
