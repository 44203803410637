import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
// Assets
import CloseIcon from '../../assets/svg/CloseIcon';
import LogoIcon from '../../assets/svg/Logo';

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className='animate whiteBg' sidebarOpen={sidebarOpen}>
      <SidebarHeader className='flexSpaceCenter'>
        <div className='flexNullCenter'>
          <LogoIcon />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className='animate pointer'
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className='flexNullCenter flexColumn'>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='home'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Início
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='services'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Serviços
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='projects'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Projetos
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='blog'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Tecnologias
          </Link>
        </li>
        {/* <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='pricing'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Pricing
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: '10px 15px' }}
            to='contact'
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li> */}
      </UlStyle>
      <UlStyle className='flexSpaceCenter'>
        <li className='semiBold font15 pointer flexCenter'>
          <a
            href='https://www.linkedin.com/company/xcodingdev/'
            rel='noreferrer'
            target='_blank'
            style={{ padding: '10px 20px', color: '#fff' }}
          >
            <svg
              stroke='currentColor'
              fill='currentColor'
              stroke-width='0'
              viewBox='0 0 448 512'
              class='jss49 fab fa-linkedin'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z'></path>
            </svg>
          </a>
        </li>
        <li className='semiBold font15 pointer flexCenter'>
          <a
            href='https://www.facebook.com/xcodingbr'
            className='radius8'
            target='_blank'
            rel='noreferrer'
            style={{ padding: '10px 20px', color: '#fff' }}
          >
            <svg
              stroke='currentColor'
              fill='currentColor'
              stroke-width='0'
              viewBox='0 0 448 512'
              class='jss49 fab fa-facebook'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z'></path>
            </svg>
          </a>
        </li>
        <li className='semiBold font15 pointer flexCenter'>
          <a
            href='https://www.instagram.com/xcoding_/'
            className='radius8'
            target='_blank'
            rel='noreferrer'
            style={{ padding: '10px 20px', color: '#fff' }}
          >
            <svg
              stroke='currentColor'
              fill='currentColor'
              stroke-width='0'
              viewBox='0 0 448 512'
              class='jss49 fab fa-instagram'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z'></path>
            </svg>
          </a>
        </li>
        <li className='semiBold font15 pointer flexCenter'>
          <a
            href='https://api.whatsapp.com/send?phone=5541999078107'
            className='radius8'
            target='_blank'
            rel='noreferrer'
            style={{ padding: '10px 20px', color: '#fff' }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1em'
              height='1em'
              fill='currentColor'
              shape-rendering='geometricPrecision'
              text-rendering='geometricPrecision'
              image-rendering='optimizeQuality'
              fill-rule='evenodd'
              clip-rule='evenodd'
              viewBox='0 0 640 640'
            >
              <path d='M579.999 0H60C27 0 0 27 0 60v520c0 33 27 60 60 60h519.999c33 0 60-27 60-60V60c0-33-27-60-60-60zM473.143 165.876c-40.666-40.748-94.726-63.166-152.34-63.166-118.655 0-215.223 96.592-215.223 215.329 0 37.95 9.921 75.036 28.772 107.647l-30.59 111.604 114.178-29.93c31.489 17.138 66.887 26.21 102.863 26.21h.071c118.667 0 215.353-96.604 215.353-215.319 0-57.579-22.394-111.639-63.084-152.364v-.011zm-152.27 331.362c-32.185 0-63.662-8.67-91.135-24.98l-6.555-3.898-67.76 17.776 18.094-66.06-4.217-6.792c-18.06-28.43-27.425-61.347-27.425-95.221 0-98.706 80.28-179.057 179.093-179.057 47.776 0 92.812 18.697 126.58 52.477 33.744 33.827 52.359 78.768 52.359 126.639-.095 98.788-80.399 179.093-179.034 179.093v.023zm98.139-134.116c-5.339-2.705-31.831-15.65-36.756-17.469-4.89-1.842-8.516-2.752-12.166 2.693-3.52 5.363-13.878 17.54-17.032 21.154-3.141 3.555-6.26 4.04-11.61 1.347-5.374-2.693-22.784-8.41-43.312-26.717-15.98-14.292-26.787-31.938-29.953-37.288-3.177-5.315-.342-8.339 2.386-10.926 2.48-2.41 5.374-6.295 8.09-9.437 2.73-3.141 3.556-5.338 5.351-9 1.772-3.543.898-6.673-.449-9.413-1.346-2.693-12.153-29.221-16.523-39.993-4.335-10.524-8.823-9.06-12.142-9.272-3.166-.13-6.697-.13-10.288-.13-3.543 0-9.378 1.335-14.35 6.733-4.926 5.327-18.827 18.378-18.827 44.87 0 26.493 19.228 52.04 21.957 55.69 2.74 3.567 37.96 57.957 91.926 81.26 12.839 5.493 22.855 8.8 30.638 11.328 12.922 4.11 24.615 3.508 33.886 2.138 10.323-1.524 31.82-12.98 36.32-25.572 4.523-12.579 4.523-23.303 3.153-25.535-1.287-2.398-4.819-3.697-10.287-6.473l-.012.012z'></path>
            </svg>
          </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
