import React from 'react';
import styled from 'styled-components';
import Tec10 from '../../assets/img/tec/aws.png';
import Tec12 from '../../assets/img/tec/digitalocean.png';
import Tec11 from '../../assets/img/tec/gcp.png';
import Tec5 from '../../assets/img/tec/mongo.png';
import Tec7 from '../../assets/img/tec/mysql.png';
import Tec1 from '../../assets/img/tec/node.png';
import Tec6 from '../../assets/img/tec/postgres.png';
import Tec9 from '../../assets/img/tec/rabbit.png';
import Tec4 from '../../assets/img/tec/react-native.png';
import Tec2 from '../../assets/img/tec/react.png';
import Tec8 from '../../assets/img/tec/redis.png';
import Tec3 from '../../assets/svg/nextjs.svg';
import StepsBox from '../Elements/StepsBox';
// Components
import TecBox from '../Elements/TecBox';

export default function Blog() {
  return (
    <Wrapper id='blog'>
      <div className='whiteBg'>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>
              Principais tecnologias utilizadas
            </h1>
            {/* <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div className='row textCenter flexCenter mb-30'>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec1} title='Node.js' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec2} title='React' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec3} title='Next.js' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec4} title='React Native' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec8} title='Redis' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec9} title='RabbitMQ' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec6} title='PostgreSQL' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec5} title='MongoDB' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec7} title='MySQL' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec10} title='AWS' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec11} title='GCP' />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
              <TecBox img={Tec12} title='DigitalOcean' />
            </div>
          </div>
        </div>
      </div>
      <div className='lightBg' style={{ padding: '50px 0' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Etapas do desenvolvimento</h1>
            {/* <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
            <StepsBox />
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 80px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  padding-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
